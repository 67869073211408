/* Custom classes */

.activityType {
  color: #f1f1f1;
  text-align: center;
  text-transform: uppercase;
}

.clock {
  text-align: right;
  width: fit-content;
}

.currentTime {
  font-size: calc(18px + 1vmin);
  margin: 10px 10px;
  text-align: right;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.errorpage {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error404 {
  font-size: calc(100px + 1vmin);
}

/* InfoWindow close button */
.gm-style-iw + div {
  display: none;
}

.gm-ui-hover-effect {
  display: none !important;
}

.half {
  width: 50%;
}

.helpText {
  color: rgba(241, 241, 241, 0.5);
  font-size: calc(8px + 0.5vmin);
}

.homePage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.homePageInfo > li {
  padding: 10px;
}

.infoWindow {
  color: #222222;
}

.infoWindow > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.infoWindowIcon {
  display: inline;
  margin-right: 0.275rem;
  width: 1rem;
  height: 1rem;
}

.lastUpdateText {
  font-size: calc(8px + 1vmin);
  margin: 10px auto 50px;
}

.leaflet-container {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
  font-weight: 500;
}

.locationId {
  color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 10px;
  text-align: left;
}

.locationSignature {
  color: rgba(241, 241, 241, 0.25);
  font-size: calc(8px + 0.5vmin);
}

.mapWindowHeader {
  background: rgba(34, 34, 34, 0.85);
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  z-index: 99;
}

.mapWindowHeaderIcon {
  display: inline;
  margin-right: 0.275rem;
  width: 1.5rem;
  height: 1.5rem;
}

.noContentError {
  margin: 0 auto;
  text-align: center;
}

.pageTitle {
  width: auto;
}

.scheduleLocation {
  width: 400px;
}

.scheduleTrack {
  text-align: center;
  width: 80px;
}

.scheduleTimeAtLocation {
  width: 200px;
}

.scheduleInfo {
  width: auto;
}

.stationBoard {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.stationBoardTrainIdent {
  text-align: left;
  width: 80px;
}

.stationBoardLocation {
  width: 250px;
}

.stationBoardTimeAtLocation {
  width: 80px;
}

.stationBoardTrackAtLocation {
  text-align: center;
  width: 80px;
}

.stationBoardInfo {
  min-width: 250px;
}

.tableContent {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;
  width: 100%;
}

.tableContent::-webkit-scrollbar {
  display: none;
}

.trainInfo {
  font-size: calc(12px + 0.5vmin);
}

.trainMessageCard {
  background-color: #333;
  margin: 10px;
  padding: 10px;
}

.trainStatus {
  margin: 0px 10px;
}

/* Loading indicator */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  margin: 1rem;
}

.loading-dot {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1rem;
  background-color: rgb(200, 200, 200);
  display: inline-block;
  margin: 0.5rem;
  animation: loading 6s cubic-bezier(0.76, 0, 0.24, 1) infinite;
  vertical-align: middle;
}

.loading-dot:nth-child(1) {
  animation-delay: 0s;
}
.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}
.loading-dot:nth-child(3) {
  animation-delay: 0.6s;
}
.loading-dot:nth-child(4) {
  animation-delay: 0.8s;
}
.loading-dot:nth-child(5) {
  animation-delay: 1s;
}
.loading-dot:nth-child(6) {
  animation-delay: 1.2s;
}
.loading-dot:nth-child(7) {
  animation-delay: 1.4s;
}
.loading-dot:nth-child(8) {
  animation-delay: 1.6s;
}
.loading-dot:nth-child(9) {
  animation-delay: 1.8s;
}
.loading-dot:nth-child(10) {
  animation-delay: 2s;
}

@keyframes loading {
  0%,
  100% {
    background-color: rgb(225, 225, 225);
    height: 0.5rem;
  }
  20% {
    height: 3rem;
  }
  40% {
    height: 0.5rem;
  }
  50% {
    background-color: rgb(175, 175, 175);
    height: 0.5rem;
  }
  70% {
    height: 3rem;
  }
  90% {
    height: 0.5rem;
  }
}
