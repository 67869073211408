@tailwind base;
@tailwind components;
@tailwind utilities;

/* Styling HTML elements */
html {
  scroll-behavior: smooth;
}

body {
  @apply bg-gray-50 dark:bg-gray-900 tabular-nums;
}

button[type="submit"] {
  @apply bg-violet-700 hover:bg-violet-800 dark:bg-violet-700 dark:hover:bg-violet-600 text-white font-semibold py-2 px-3 mx-3 my-5 rounded-xl transition ease-in-out duration-200;
}

form {
  @apply accent-violet-700;
}

.card h2 {
  @apply mt-0;
}

hr {
  @apply my-2;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="date"] {
  @apply mx-3 mb-3 p-2 border border-gray-300 bg-gray-50 dark:bg-gray-600 dark:border-gray-800 rounded-md;
}

main {
  @apply container mx-auto px-2;
}

textarea {
  @apply mx-3 p-2 border border-gray-300 bg-gray-50 dark:bg-gray-600 dark:border-gray-800 rounded-md;
}

/* Custom classes */
.card {
  @apply mx-auto my-6 max-w-4xl prose dark:prose-invert bg-white dark:bg-gray-800 shadow rounded-xl p-3;
}

.invisible-card {
  @apply mx-auto my-6 max-w-4xl prose dark:prose-invert p-3;
}

.map-card {
  @apply mx-auto my-6 max-w-4xl bg-white dark:bg-gray-800 shadow rounded-xl overflow-hidden;
}

.footer {
  @apply mt-10 mb-5 mx-5 text-tiny text-gray-400 dark:text-gray-500 text-center;
}

/* Skip to search box link */
.skip-to-search {
  padding: 0.5rem 1.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  position: absolute;
  left: 0.5rem;
  transform: translateY(-120%);
  transition: transform 325ms ease-in;
}

.skip-to-search:focus {
  transform: translateY(0);
}

.sweetsweethoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.swishImage {
  max-width: 333px;
  width: 90%;
}

.success {
  @apply mx-auto mt-5 mb-5 max-w-4xl prose dark:prose-invert bg-green-600 text-white shadow rounded-xl p-3;
}

.warning {
  @apply mx-auto mt-5 mb-5 max-w-4xl prose dark:prose-invert bg-orange-700 text-white shadow rounded-xl p-3;
}

#loading {
  width: 200px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.6s;
  font-family: monospace;
}